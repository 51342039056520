import { http } from '@/utils'
import { Flex, message, Modal, ModalProps } from 'antd'
import axios from 'axios'
import { CandleTooltipCustomCallbackData, dispose, init } from 'klinecharts'
import React, { useEffect, useRef } from 'react'

interface KlineModalProps extends ModalProps {
  params: {
    batchId: number
    code: number
    codeName: string
    dateStart: string
    dateEnd: string
  }
}

const KlineModal: React.FC<KlineModalProps> = ({ params, ...modalProps }) => {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (modalProps.open && chartRef.current) {
      const chart = init(chartRef.current)!
      chart.setStyles({
        candle: {
          bar: {
            upColor: '#F92855',
            downColor: '#2DC08E',
            noChangeColor: '#888888',
            upBorderColor: '#F92855',
            downBorderColor: '#2DC08E',
            noChangeBorderColor: '#888888',
            upWickColor: '#F92855',
            downWickColor: '#2DC08E',
            noChangeWickColor: '#888888',
          },
          tooltip: {
            custom: (data: CandleTooltipCustomCallbackData) => {
              const { prev, current } = data
              const prevClose = prev?.close ?? current.open
              const change = ((current.close - prevClose) / prevClose) * 100
              return [
                { title: 'time', value: '{time}' },
                { title: 'open', value: current.open.toFixed(2) },
                { title: 'high', value: current.high.toFixed(2) },
                { title: 'low', value: current.low.toFixed(2) },
                { title: 'close', value: current.close.toFixed(2) },
                { title: 'volume', value: '{volume}' },
                {
                  title: 'Change: ',
                  value: {
                    text: `${change.toFixed(2)}%`,
                    color: change < 0 ? '#2DC08E' : '#F92855',
                  },
                },
              ]
            },
          },
        },
        indicator: {
          bars: [
            {
              upColor: '#F92855',
              downColor: '#2DC08E',
              noChangeColor: '#888888',
            },
          ],
        },
      })
      chart.createIndicator('VOL')
      chart.createIndicator('MA', true, { id: 'candle_pane' })

      try {
        const loadList = async () => {
          const res = await http.post('/v1/market/kline', { batch_id: params.batchId, period: 'daily', code: params.code, date_start: params.dateStart, date_end: params.dateEnd })
          chart.applyNewData(res.data.list)
          chart.removeOverlay()
          if (res.data.begin_at > 0) {
            chart.createOverlay({ name: 'simpleAnnotation', points: [{ timestamp: res.data.begin_at, value: res.data.entry_price }], extendData: 'B ' + res.data.entry_price, styles: { line: { color: '#F92855' }, text: { backgroundColor: '#F92855' }, polygon: { color: '#F92855' } } })
          }
          if (res.data.end_at > 0) {
            chart.createOverlay({ name: 'simpleAnnotation', points: [{ timestamp: res.data.end_at, value: res.data.clear_price }], extendData: 'S ' + res.data.clear_price, styles: { line: { color: '#2DC08E' }, text: { backgroundColor: '#2DC08E' }, polygon: { color: '#2DC08E' } } })
          }
        }
        loadList()
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error(error.response?.data?.message || '失败')
        }
      }

      return () => {
        if (chartRef.current) {
          dispose(chartRef.current)
        }
      }
    }
  }, [modalProps.open])

  return (
    <>
      <Modal
        title={'走势 ' + params.code + '-' + params.codeName}
        centered
        width={1000}
        styles={{ body: { padding: 0, height: 600 } }}
        footer={null}
        {...modalProps}
      >
        <Flex
          justify="center"
          align="center"
          style={{ height: '100%' }}
        >
          <div
            id="chart"
            ref={chartRef}
            style={{ width: '100%', height: '100%' }}
          />
        </Flex>
      </Modal>
    </>
  )
}

export default KlineModal
